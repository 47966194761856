import { ComplexAction, Modal, OptionList, Text, TextField, Thumbnail } from "@shopify/polaris";
import { OptionDescriptor } from "@shopify/polaris/build/ts/src/types";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import {
  getPurchasableImage,
  getVariantImageFromPurchasableMap,
} from "@smartrr/shared/utils/useVariantToPurchasableMap";
import { flatten, xor } from "lodash";
import React from "react";

import {
  FixedSearch,
  ModalProductTitle,
  ModalVariantTitle,
  ThumbnailContainer,
} from "@vendor-app/app/_sharedComponents/BrowseProductsModal/components";
import { useVendorPortalVariantToPurchasableMap } from "@vendor-app/app/_state/reducers/purchasables";
import { formatProductAndVariantNameForDisplay } from "@smartrr/shared/utils/displayUtils";

interface Props {
  //option list and filter props
  searchSuffix?: React.ReactNode;
  searchText?: string;
  selectedProductsAndVariants: string[];
  purchasableOptions: IPurchasable[];

  onSearchChange: (text: string) => void;
  setSelectedProductAndVariantIds: (ids: string[]) => void;
  displayWarningOnSequentialProduct?: (variantIds?: string[]) => void;
  reachedMaxQuantity?: (itemId: string) => void;
  //modal props
  open: boolean;
  title: string | React.ReactNode;
  titleHidden?: boolean;
  limitHeight?: boolean;
  primaryAction: ComplexAction | undefined;
  secondaryActions?: ComplexAction[] | undefined;
  footer?: React.ReactNode;

  onClose: () => void;
}

export const BrowseProductsModal = ({
  title,
  titleHidden = false,
  limitHeight = true,
  open,
  primaryAction,
  secondaryActions,
  footer,
  searchSuffix,
  searchText,
  selectedProductsAndVariants,
  purchasableOptions,
  onClose,
  onSearchChange,
  displayWarningOnSequentialProduct,
  setSelectedProductAndVariantIds,
  reachedMaxQuantity,
}: Props) => {
  const variantToPurchasableMap = useVendorPortalVariantToPurchasableMap();

  return (
    <Modal
      titleHidden={titleHidden}
      limitHeight={limitHeight}
      title={title}
      open={open}
      onClose={onClose}
      primaryAction={primaryAction}
      secondaryActions={secondaryActions}
      footer={footer}
    >
      <FixedSearch>
        <Modal.Section>
          <TextField
            autoComplete="off"
            label=""
            placeholder="Search products"
            value={searchText}
            onChange={onSearchChange}
            suffix={searchSuffix}
          />
        </Modal.Section>
      </FixedSearch>

      <Modal.Section flush>
        <OptionList
          allowMultiple
          verticalAlign="center"
          selected={selectedProductsAndVariants}
          options={
            purchasableOptions.reduce((acc: OptionDescriptor[], p) => {
              const imageUrl = getPurchasableImage(p);
              const label = <ModalProductTitle>{p.purchasableName}</ModalProductTitle>;
              const result: OptionDescriptor = {
                label,
                value: p.shopifyId!,
                ...(imageUrl && {
                  media: <Thumbnail size="small" source={imageUrl} alt={p.purchasableName} />,
                }),
              };
              const variants = flatten(
                (p.vnts || []).map(v => ({
                  ...v,
                  purchasableName: p.purchasableName,
                }))
              )
                .sort()
                .filter(v => !!v.shopifyId && !v.isDraftOrArchived)
                .map(v => {
                  const imageUrl = getVariantImageFromPurchasableMap(variantToPurchasableMap, v);
                  const variantName = formatProductAndVariantNameForDisplay({
                    purchasableName: v.purchasableName,
                    purchasableVariantName: v.purchasableVariantName,
                    isActive: v.isActiveInShopify,
                  });
                  const label = (
                    <ModalVariantTitle title={`${variantName} ${v.sku}`}>
                      {variantName}
                      {v.sku ? (
                        <Text variant="bodyMd" as="span" color="subdued">
                          {v.sku}
                        </Text>
                      ) : null}
                    </ModalVariantTitle>
                  );
                  const result: OptionDescriptor = {
                    label,
                    value: v.shopifyId!,
                  };

                  result.media = (
                    <ThumbnailContainer>
                      {!!imageUrl && <Thumbnail size="small" source={imageUrl} alt={variantName} />}
                    </ThumbnailContainer>
                  );
                  return result;
                });
              acc.push(result, ...variants);
              return acc;
            }, []) || []
          }
          onChange={selected => {
            //find an item that was selected or deselected
            const addedItem = xor(selected, selectedProductsAndVariants)[0];

            // check if user has already selected max num of products (not counting variants)
            if (reachedMaxQuantity?.(addedItem)) {
              return;
            }

            //check if that item is a variant
            if (addedItem && addedItem.includes("ProductVariant")) {
              //find corresponding product in our db (purchasableOptions)
              const correspondingProduct = purchasableOptions.find(product =>
                product.vnts?.find(variant => variant.shopifyId === addedItem)
              );
              displayWarningOnSequentialProduct?.(correspondingProduct?.vnts?.map(vnt => vnt.id));

              //find all variants related to that product and map them by id,
              //filter out products that have 0 variants
              const correspondingProductVariants =
                correspondingProduct?.vnts
                  ?.filter(variant => variant.isActiveInShopify && variant.shopifyId)
                  .map(variant => variant.shopifyId) || [];
              //check if all variants are selected and addedItem was selected previously
              if (
                correspondingProduct?.shopifyId &&
                correspondingProductVariants.length &&
                correspondingProductVariants.every(variantId => selected.includes(variantId!)) &&
                selected.includes(addedItem)
              ) {
                //if all variants are indeed selected then select their corresponding product
                setSelectedProductAndVariantIds([...selected, correspondingProduct.shopifyId]);
                return;
              }
              //check if all variants are deselected and addedItem was deselected previously
              if (
                correspondingProduct?.shopifyId &&
                correspondingProductVariants.length &&
                correspondingProductVariants.every(variantId => !selected.includes(variantId!)) &&
                !selected.includes(addedItem)
              ) {
                //if all variants are indeed deselected then deselect their corresponding product
                setSelectedProductAndVariantIds(
                  selected.filter(
                    id => !correspondingProductVariants.includes(id) && id !== correspondingProduct.shopifyId
                  )
                );
                return;
              }
              //check if variant was deselected previously and its product is selected
              if (
                correspondingProduct?.shopifyId &&
                correspondingProductVariants.length &&
                correspondingProductVariants.some(variantId => selected.includes(variantId!)) &&
                !selected.includes(addedItem) &&
                selectedProductsAndVariants.includes(correspondingProduct.shopifyId)
              ) {
                //if not all variants of product are selected then product should be deselected
                setSelectedProductAndVariantIds(selected.filter(item => item !== correspondingProduct.shopifyId));
                return;
              }
            }
            //check if that item is a product
            if (addedItem && !addedItem.includes("ProductVariant")) {
              //find corresponding product in our db (purchasableOptions)
              const correspondingProduct = purchasableOptions.find(product => product.shopifyId === addedItem);
              displayWarningOnSequentialProduct?.(correspondingProduct?.vnts?.map(vnt => vnt.id));

              //find all variants related to that product and map them by id,
              //filter out products that have 0 variants
              const correspondingProductVariants =
                correspondingProduct?.vnts
                  ?.filter(variant => variant?.isActiveInShopify && variant.shopifyId != undefined)
                  .map(variant => variant.shopifyId!) || [];
              //check if product was deselected
              if (
                correspondingProduct?.shopifyId &&
                correspondingProductVariants.length &&
                !selected.includes(addedItem)
              ) {
                //if product was deselected then all of its variants should be also deselected
                setSelectedProductAndVariantIds(
                  selected.filter(id => !correspondingProductVariants.includes(id))
                );
                return;
              }
              //check if product was selected
              if (
                correspondingProduct?.shopifyId &&
                correspondingProductVariants.length &&
                selected.includes(addedItem)
              ) {
                //if product was selected then all of its variants should be selected
                setSelectedProductAndVariantIds([
                  ...new Set([...selected, ...correspondingProductVariants, correspondingProduct.shopifyId]),
                ]);
                return;
              }
            }
            setSelectedProductAndVariantIds(selected);
          }}
        />
      </Modal.Section>
    </Modal>
  );
};
